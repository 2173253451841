import * as React from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import "./benefit-one-item.css";

const BenefitOneItems = ({
  className = "",
  comprehensiveProtection,
  safeguardSMSFsFromUnexpected,
}) => {
  return (
    <div className={`benefit-one-items ${className}`}>
      <div className="bgl-upper-text">
        <img
          src="/images/bgl/tick-icon.svg"
          alt="tick"
          loading="lazy"
          className="tick-cion"
        />
        <h2 className="comprehensive-protection">{comprehensiveProtection}</h2>
      </div>

      <div className="bgl-bottom-text">{safeguardSMSFsFromUnexpected}</div>
    </div>
  );
};

BenefitOneItems.propTypes = {
  className: PropTypes.string,
  comprehensiveProtection: PropTypes.string,
  safeguardSMSFsFromUnexpected: PropTypes.string,

  /** Style props */
  propPadding: PropTypes.any,
  propMinHeight: PropTypes.any,
};

export default BenefitOneItems;
