import React, { useEffect } from "react";

import Layout from "../components/Layout";
import BenefitOneItems from "../components/bgl/BenefitOneItems";

import { withPrefix } from "gatsby";

const BGLBanner = () => {
  return (
    <div className="banner">
      <div className="content nu-alt2-c">
        <div className="banner-wr" style={{ alignItems: "flex-end" }}>
          <div className="banner-txt text-white nu-c">
            <h1>
              Protect your SMSF clients with AuditCover tax audit insurance
            </h1>
            <p className="banner-p text-white">
              <strong>
                Streamline audit protection with AuditCover and BGL.
              </strong>
              <br />
              AuditCover integrates with BGL, enabling accountants to give SMSF
              clients an option for cover as protection against unexpected
              accounting fees when responding to tax audits.
            </p>
            <div className="banner-btn-group">
              <a
                href="https://portal.auditcover.com/sso/bgl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={withPrefix("images/bgl/connect-btn.svg")}
                  alt="Tax audit insurance made easy"
                  loading="lazy"
                  className="bgl-banner-connect-btn"
                />
              </a>
            </div>
          </div>
          <div className="banner-img">
            <div className="banner-img-partner">
              <img
                src={withPrefix("images/hero-pa.png")}
                alt="Tax audit insurance made easy"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ACBenefits = () => {
  return (
    <section className="content nu-c">
      <div className="benefits-content-wrapper">
        <div className="benefits-content">
          <div className="title-text-parent">
            <h2 className="why-choose-auditcover">
              Why choose AuditCover for your SMSF clients
            </h2>

            <div className="benefit-list">
              <BenefitOneItems
                comprehensiveProtection="Comprehensive Protection"
                safeguardSMSFsFromUnexpected="Safeguard SMSFs from unexpected professional fees during government audits."
              />
              <BenefitOneItems
                comprehensiveProtection="Seamless Integration"
                safeguardSMSFsFromUnexpected="Easily integrate with BGL to generate hassle-free tax audit insurance quotes."
              />
              <BenefitOneItems
                comprehensiveProtection="Automated Processes"
                safeguardSMSFsFromUnexpected="Simplify each step of the process by automating the campaign setup, quote creation, and annual policy renewal."
              />
              <div className="benefit-items-b-parent">
                <BenefitOneItems
                  comprehensiveProtection="Flexible Client Coverage"
                  safeguardSMSFsFromUnexpected="Send personalised quotes to all your clients in one go, with options to send new client quotes upon onboarding."
                />
                <BenefitOneItems
                  comprehensiveProtection="Cost-Free to Your Practice"
                  safeguardSMSFsFromUnexpected="Become a referral partner without any additional costs or fees."
                />
              </div>
            </div>
          </div>

          <div className="image-content-wrapper">
            <div className="image-content">
              <div className="image-top-shape" />
              <img
                className="image-20-icon"
                loading="lazy"
                alt="bgl"
                src="/images/bgl/bgl.svg"
              />
              <div className="image-bottom-shape" />
              <img
                className="logomark-icon1"
                loading="lazy"
                alt="auditcover"
                src="/images/bgl/ac-icon.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ACIntroduction = () => {
  return (
    <div className="introduction-container">
      <div className="content blg-c">
        <div className="nu-man-txt text-white introduction-width">
          <h1>Why AuditCover?</h1>
          <p>
            AuditCover is a pioneer in tax audit insurance, protecting SMSFs,
            businesses, and individuals from unforeseen professional fees when
            responding to tax audits. Our innovative solutions and easy-to-use
            tools support accountants to educate their clients on our
            comprehensive solution for audit protection.
          </p>
          <p>
            Give your clients peace of mind with the option to purchase
            AuditCover's reliable tax audit insurance.
          </p>
        </div>
      </div>
    </div>
  );
};

const ACSteps = () => {
  return (
    <div className="step-container nu-icons-container bottom-radius ">
      <div className="content nu-c">
        <h1 className="nu-icon--title">How to get started</h1>

        <div className="nu-icons">
          <div className="nu-icon--item">
            <img src="/images/bgl/lock.svg" alt="lock" loading="lazy" />
            <span>Sign in</span>
            <div>
              Log into your BGL account today and connect directly to AuditCover
            </div>
          </div>

          <div className="nu-icon--item">
            <img src="/images/bgl/user.svg" alt="user" loading="lazy" />
            <span>Set up your profile</span>
            <div>
              Set up an AuditCover account to get started as a referring firm
            </div>
          </div>

          <div className="nu-icon--item">
            <img src="/images/bgl/firework.svg" alt="fire" loading="lazy" />
            <span>Refer your clients</span>
            <div>
              Deliver personalised tax audit insurance quotes to your client
              base
            </div>
          </div>
        </div>

        <a
          href="https://portal.auditcover.com/sso/bgl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={withPrefix("images/bgl/connect-btn-blue.svg")}
            alt="Tax audit insurance made easy"
            loading="lazy"
            className="bgl-banner-connect-btn"
          />
        </a>
      </div>
    </div>
  );
};

const ACImage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.storylane.io/js/v2/storylane.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bgl-image-container ">
      <div className="iframe-wrapper iframe-content">
        <iframe
          loading="lazy"
          className="sl-demo"
          src="https://app.storylane.io/demo/qmi1v6v17kad"
          name="sl-embed"
          allow="fullscreen"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "643px",
            border: "1px solid rgba(63,95,172,0.35)",
            boxShadow: "0px 0px 18px rgba(26, 19, 72, 0.15)",
            borderRadius: "10px",
            boxSizing: "border-box",
          }}
        ></iframe>
      </div>
    </div>
  );
};

const BGLLandingPage = () => {
  return (
    <Layout
      bg="partners-bg"
      banner={<BGLBanner />}
      isBgl={true}
      isDisplayGetQuoteBtn={false}
    >
      <ACBenefits />
      <ACImage />
      <ACSteps />
      <ACIntroduction />
    </Layout>
  );
};

export default BGLLandingPage;
